import store from '@/store';
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import tasqFeedbackModule from './tasqFeedbackModule';
import axiosClient from '@/lib/rest/axiosClient';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'gptModule',
  store,
})
class GptModule extends VuexModule {
  seeSimilars: any[] = [];
  recommendations: any[] = [];
  forms: any[] = [];
  lastActions: any[] = [];
  overviewHidden = false;

  isLoadingActions =  false;

  @Mutation
  setSeeSimilars({ results }: { results: any[] }): void {
    if (results) {
      this.seeSimilars = results;
    }
  }

  @Mutation
  setRecommendations({ recommendations }: { recommendations: any[] }): void {
    if (recommendations !== null) {
      this.recommendations = recommendations;
    }
  }

  @Mutation
  setForms(data: any[]): void {
    this.forms = data;
  }


  @Mutation
  setOverviewHidden(data): void {
    this.overviewHidden = data;
  }


  @Mutation
  setActionsLoading(data): void {
    this.isLoadingActions = data;
  }

  @Mutation
  setLastActions({ results }: { results: any[] }): void {
    console.log(results);
    if (results) {
      const comments = results['comments'];
      const alarms = results['alarms'];
      const changepoints = results['changepoints'];
      this.lastActions = [...comments, ...alarms, ...changepoints];
    }
  }

  @Mutation
  setLastActionsActive(date): void {
    console.log(this.lastActions);
    const updatedActions = this.lastActions.map((action) => {
      if (action.date === date) {
        action.active = true;
      } else {
        action.active = false;
      }
      console.log(action);
      return action;
    });

    console.log(updatedActions);
    this.lastActions = updatedActions;
  }

  @Mutation
  setLastActionsInActive(): void {
    const updatedActions = this.lastActions.map((action) => {
      action.active = false;
      console.log(action);
      return action;
    });

    console.log(updatedActions);
    this.lastActions = updatedActions;
  }

  @Mutation
  resetSeeSimilars(): void {
    this.seeSimilars = [];
  }

  @Mutation
  resetRecommendations(): void {
    this.recommendations = [];
  }

  @Mutation
  resetForms(): void {
    tasqFeedbackModule.resetAllData();
    this.forms = [];
  }

  @Mutation
  resetLastActions(): void {
    this.lastActions = [];
  }

  @Action
  async getGptLastActions(payload) {
    try {
      console.log(payload);
      if(!payload.wellMetaData){
        return;
    }
      const response = await axiosClient.post('/ai/actions', payload);
      console.log('Response for', 'ai/actions first', ':', response.data);

      // const { results } = response.data;
      this.setLastActions(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  @Action
  async postGptComment(payload: any) {
    try {
      console.log(payload);
      if(!payload.body){
          return;
      }
      const response = await axiosClient.post('/ai/comments', payload);
      console.log('Response for', 'ai/actions first', ':', response.data);
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }
}

export default getModule(GptModule);
