
import gptModule from '@/store/modules/gptModule';
import { getComponent } from '@/utils/helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    // GptRecommendation: () => getComponent("gpt/GptRecommendation"),
  },
})
export default class GptRecommendations extends Vue {
  @Prop({ type: Object, required: true }) tasq?: any;

  activeTab = '';

  // recommendation: any =  {status: ''}

  status = '';

  get recommendation(){
    const recommendation: any = gptModule.recommendations ? gptModule.recommendations: {}
    return {
      status: '',
      paragraphs: this.formattedRecommendations(recommendation)
    }
  }

  formattedRecommendations(recommendation) {
    const lines = recommendation.split("\n");

      // Get the first line before the colon (:) and add the class "text-orange"
      const firstLine = lines[0].split(":");
      lines[0] = `<p class="text-lg font-semibold">${firstLine[0]? firstLine[0].replace("-", ""): firstLine[0]}</p>`;

      // Replace the numbering (1., 2., etc.) with the specified div
      for (let i = 1; i < lines.length; i++) {
        lines[i] = lines[i].replace(/(\d+\.)\s+/g, '<div class="hidden bullet-point"></div> ');
      }

      // Replace hyphens (-) with the specified div
      for (let i = 1; i < lines.length; i++) {
        lines[i] = lines[i].replace(/-\s+/g, '<div class="hidden bullet-point"></div> ');
      }

      // Wrap the text after <div> with <span> and apply styling
      for (let i = 1; i < lines.length; i++) {
        lines[i] = lines[i].replace(
          /<\/div>\s*((?:(?!<\/div>).)*)/g,
          '</div><span class="bullet-text list-item mx-3">$1</span>'
        );
      }

      return lines.join("<br/>");
    }
}
